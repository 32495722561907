/* Insecticides */
.Page {
  font-family: Lato;
}
.page-title {
  position: relative;
  background-attachment: fixed;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 90px 0px 140px;
  background-image: url("./back.jpg");
  height: 330px;
}
.page-title h1 {
  color: #ffffff;
  font-size: 50px;
  font-weight: 600;
  text-align: center;
}
.page-title:before {
  position: absolute;
  content: "";
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  display: block;
  background-color: rgba(214, 198, 126, 0.72);
}

h1,
h2,
h3,
h4,
h5,
h6 {
  position: relative;
  font-family: "Source Sans Pro", sans-serif;
  font-weight: normal;
  margin: 0px;
  background: none;
  line-height: 1.6em;
}
.info {
  background-color: #05613f;
}
.info ul {
  padding: 15px 10px 15px 50px;
  display: flex;
}
.info ul li {
  margin-left: 10px;
}
.h1 {
  font-family: Lato;
  margin: 40px 0px 10px 40px;
}

.Under-img {
  margin: 0px 40px;
}
