body {
  font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
    "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
}
.Sec2 {
  font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
    "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
  margin-top: 100px;
  margin-bottom: 50px;
}
.wlcm-div {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.wlcm-div h3 {
  color: #0b7750;
  font-weight: bold;
}
.vision {
  padding: 20px 120px;
}
.vision h3 {
  margin-left: auto;
  color: #666666;
  font-size: 20px;
  font-weight: 700;
  letter-spacing: 0px;
  line-height: 26px;
  margin: 0;
  padding: 0;
  text-align: center;
}

@media screen and (max-width: 550px) {
  .vision {
    padding: 20px 40px;
  }
}
@media screen and (max-width: 450px) {
  .vision {
    padding: 20px 20px;
  }
}
@media screen and (max-width: 380px) {
  .vision {
    padding: 10px 20px;
  }
  .wlcm-div h1 {
    font-size: 30px;
    font-weight: bold;
  }
}
@media screen and (max-width: 330px) {
  .vision {
    padding: 10px 20px;
  }
  .wlcm-div h1 {
    font-size: 22px;
  }
}
