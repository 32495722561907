.vision-item img{
    height: 150px;
    width: 150px;
  }
.vision-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.Vision{
    padding-left: 50px;
    padding-right: 50px;
    background-color: #c4c1c1;
   
    
}
.Vision h1{
    color: #0b7750;
    display: flex;
    flex-direction: column;
    align-items: center;
  /* font-weight: bold; */
}
.Vision p{
    font-size: 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
    /* justify-content: center; */
}
  .summary{
    font-weight: 400;
    /* font-size: 29px; */
    color: #000000;
    line-height: 25px;
  }
  .vision-section{
    margin-top: 70px;
    display: flex;
    justify-content: space-evenly ;
  }
  .vision-section p{
      margin-top: 15px;
      font-size: 19px;
    /* font-weight: 500; */
  }
  .elementor-shape-fill{
    fill: #0b7750;
    size: 100px;
    transform-origin: center;
  }
  svg {
    overflow: hidden;
    vertical-align: middle;
}
.elementor-shape {
  overflow: hidden;
  position: absolute;
  left: 0;
  width: 100%;
  line-height: 0;
  direction: ltr;
}
@media (max-width: 767px)
{
.elementor-493 .elementor-element.elementor-element-a9a01b9 > .elementor-shape-top svg {
    width: calc(300% + 1.3px);
    height: 50px;
}

}

.elementor-493 .elementor-element.elementor-element-a9a01b9 > .elementor-shape-top svg {
  width: calc(201% + 1.3px);
}
.elementor-shape svg {
  display: block;
  width: calc(100% + 1.3px);
  position: relative;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
}