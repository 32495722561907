.Card {
  display: flex;
  margin: 30px 10px 10px 0px;
  /* background-color: #bcf5e0; */
  /* background-color: #f8e8b7; */
  width: 70%;
}
.img {
  border: 1px solid #eee;
}
.Card .img img {
  height: 250px;
  width: 220px;
  margin: 10px 50px 10px 50px;
}
.details {
  margin-left: 20px;
  /* width:0% */
}
.details h3 {
  color: #777777;
  font-size: 24px;
}

.details h6 {
  color: #000000;
  font-weight: bold;
  font-size: 20px;
}
.prd {
  /* display: flex; */
  /* align-items: center; */
  margin-top: 20px;
  font-size: 16px;
  
}
@media screen and (max-width: 940px) {
  .Card {
    width: 80%;
    margin-left: 100px;
  }
}
@media screen and (max-width: 810px) {
  .Card {
    width: 80%;
    margin-left: 70px;
  }
}
@media screen and (max-width: 685px) {
  .Card {
    flex-direction: column;
    width: 60%;
    margin-left: 120px;
  }
  .Card .img img {
    /* height: 250px;
    width: 180px; */
    margin: 10px 10px 10px 70px;
  }
}
@media screen and (max-width: 510px) {
  .Card {
    width: 80%;
    margin-left: 40px;
  }
  .Card .img img {
    /* height: 250px;
    width: 180px; */
    margin: 30px 10px 10px 90px;
  }
}
@media screen and (max-width: 380px) {
  .Card {
    width: 90%;
    margin-left: 20px;
  }
  .Card .img img {
    /* height: 250px;
    width: 180px; */
    margin: 10px 10px 10px 70px;
  }
}
