.header {
  background-color: black;
  display: flex;
  justify-content: space-between;
}
.Inquirybtn {
  color: #ffcc2c;
  background-color: #303030;
  padding: 10px 20px;
  margin-top: 8px;
}
.headerdiv {
  background-color: #0b7750;
  width: 277px;
  height: 53px;
}
.phone body {
  /* font-family: Geneva, sans-serif; */
}
body button:focus {
  outline: none;
}
.lg-nav {
  display: flex;
  flex-direction: row;
}
.nav ul {
  display: flex;
  position: relative;
  top: 40%;
}

.nav-items {
  margin-left: 100px;
}
.nav-items ul li {
  margin-left: 100px;
}
.nav-Links {
  font-size: 20px;

  color: black;
  font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
    "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
}
.navbar .nav-item Link:hover {
  color: #0b7750;
}
.nav-items .sp :hover .sd {
  color: #0b7750;
}
.nav-items ul li :hover span {
  transform: rotate(-180deg);
}
.navbutton {
  font-size: 30px;
  margin-right: 15px;
  line-height: 80px;
  position: absolute;
  right: 20px;
  top: 55px;
  display: none;
}
/* Hover Product */
.HoverProduct {
  /* width: 100px; */
  position: absolute;
  /* margin-left: 720px;
  margin-top: 70px; */
  z-index: 1;
  font-size: 14px;
  animation: transitionIn 1s;
  /* transform: translateY(70px); */
  transition: 0.3s all ease-in;
}
@keyframes transitionIn {
  from {
    opacity: 0%;
    margin-top: 100px;
  }
  to {
    opacity: 100%;
    margin-top: 70px;
  }
}
 .hoverul {
  background: #303030;

  display: flex;
  flex-direction: column;
  color: white;
  border-top: 3px solid #ffcc2c;
}
/* .hoverli {
  width: auto;
} */
/* .hoverli span {
  margin-left: -55px;
} */
 .hoverul .hoverli {
  font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
    "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
  padding: 5px 30px 10px 5px;
  border-bottom: 1px solid rgb(255, 255, 255, 0.5);
}
.Hover-Links {
  color: white;
  text-decoration: none;
}

.Hover-Links:hover {
  text-decoration: none;
  background-color: #0b7750;
  color: white;
}
.sm-nav {
  display: none;
}
.dropdown-menu{
  width:192px;
  padding: 0px;
  margin: 0px;
}
@media screen and (max-width: 1040px) {
  /* .nav-items {
    margin-left: 50px;
  } */
}
@media screen and (max-width: 960px) {
  /* .nav-items ul li {
    margin-left: 80px;
  } */
}
@media screen and (max-width: 880px) {
  /* .nav-items ul li {
    margin-left: 50px;
  } */
}
@media screen and (max-width: 760px) {
  /* .nav-items ul li {
    margin-left: 30px;
  } */
  .header {
    background-color: #0b7750;
  }
  .Inquirybtn {
    padding: 5px 10px;

    margin: 8px 50px 8px 0px;
  }
}
@media screen and (max-width: 680px) {
  /* .nav-items ul li {
    margin-left: 30px;
  } */
  /* .logo {
    margin-left: 10px;
  } */
}
@media screen and (max-width: 650px) {
  /* .navbutton {
    display: block;
  }
  .lg-nav {
    display: none;
  }
  .sm-nav {
    display: block;
  }
  .show {
    display: block;
  }
  .hide {
    display: none;
  }
  #mobile {
    position: absolute;
    left: 100px;
    top: 85px;
    animation: transitionIn 1s;

    transition: 0.3s all ease-in;
    z-index: 1;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .sm-nav ul {
    flex-direction: column;
    align-items: center;
    margin: 0px 140px 0px 100px; 
  }
  ul {
    background: #303030;
  }
  .nav-Links {
    color: grey;

    width: 100vw;
    align-items: center;
    justify-content: center;
  } */
  /* .nav-items ul .ul {
    align-items: center;
    justify-content: center;
  } */

  /* .nav-items ul li .nav-Links {
    align-items: center;
    border-bottom: 1px solid rgb(255, 255, 255, 0.5);
    justify-content: center;

     width: 100vw; 
  }
  .nav-Links:hover {
    color: white;
  }
  .mob-hover {
    margin-left: 200px;
  }
  .mob-hover Link:hover span {
    color: white;
  }
  .mob-hover span {
    color: gray;
    font-size: 20px;
  }
  .Hover-Links:hover {
    color: white;
  } */
  /* .nav-items ul li {
    margin: 10px;
  } */
  /* .Inquirybtn {
    display: none;
  }
  .header {
    justify-content: center;
  }
  .phoneimg {
    height: 40px;
    width: 200px;
  } */
}
@media screen and (max-width: 450px) {
  .Inquirybtn {
    margin: 8px 10px 8px 0px;
  }
}
@media screen and (max-width: 415px) {
  /* .headerdiv {
    width: 100px;
  } */
  .header {
    display: flex;
    flex-direction: column;
  }
  .Inquirybtn {
    display: none;
  }
}
