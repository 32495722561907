.carousel img {
  width: 100vw;
  height: 80vh;
}
.carousel .control-dots {
  display: none;
}
svg{
  color: red;
}
@media screen and (max-width: 450px) {
  .carousel img {
    height: 40vh;
  }
}
@media screen and (max-width: 365px) {
  .carousel img {
    height: 30vh;
  }
}
