.popular {
  font-family: "Lato";
  margin-top: 30px;
  background-color: #f7fafa;
  padding-bottom: 30px;
}
.Top {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.Top h3 {
  margin-left: auto;
  color: #666666;
  font-size: 20px;
  font-weight: 700;
  letter-spacing: 0px;
  line-height: 26px;
  margin: 0;
  padding: 0;
  text-align: center;
  padding: 5px 120px;
}
.carousel {
  display: flex;
  align-items: center;
  justify-content: center;
}
/* popular 2 ke liye */
.carousel2 img {
  width: 300px;
  height: 350px;
  background-color: rgb(199, 193, 193);
  /* border: 1px solid; */
}
.popular2 {
  margin-top: 20px;
  margin-bottom: 20px;
}
@media screen and (max-width: 1000px) {
  .carousel2 img {
    width: 300px;
    height: 300px;
    background-color: rgb(199, 193, 193);
    /* border: 1px solid; */
  }
}
@media screen and (max-width: 800px) {
  .carousel2 img {
    width: 250px;
    height: 300px;
    background-color: rgb(199, 193, 193);
    /* border: 1px solid; */
  }
}
@media screen and (max-width: 600px) {
  .popular2 .Top h3 {
    padding: 5px 40px;
  }
}
@media screen and (max-width: 425px) {
  .popular2 .Top h1 {
    font-size: 32px;
  }
}
@media screen and (max-width: 380px) {
  .popular2 .Top h1 {
    font-size: 30px;
  }
  .popular2 .Top h3 {
    font-size: 16px;
  }
  .carousel2 img {
    width: 200px;
    height: 250px;
    /* background-color: white; */
    /* border: 1px solid; */
  }
}

@media screen and (max-width: 271px) {
  .popular2 .Top h1 {
    margin-left: 50px;
  }
  .popular2 .Top h3 {
    padding: 10px 20px;
  }
}
