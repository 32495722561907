.footer {
  color: #fff;
  font-family: Lato;
  background-color: #222221;
  /* width: 98.5vw; */
  /* // height: 350px; */
  display: flex;
  padding: 50px 0px 0px 50px;
}
.links {
  margin-left: 30px;
}
.textftr{ 
  font-size: 19px;
}
.imgftr{
  height: 130px;
   width: 130px;
 margin-left: 120px;
 /* background-color: #ffc107; */
}
.contact h1,
.links h1 {
  font-size: 30px;
  font-weight: bold;

  /* margin: 0 0 15px;
  padding: 0 0 12px; */
  /* position: relative;
  text-align: left;
  text-transform: capitalize; */
}
.contact h3 {
  color: #ffc107;
  margin-top: 10px;
}
.contact p {
  font-size: 19px;
}
.links ul li {
  margin-top: 20px;
  font-size: 19px;
}
.lin {
  color: #fff;
  text-decoration: none;
}

.lin {
  margin-left: 10px;
}
.links span {
  color: #ffc107;
}
.links ul li:hover .lin {
  /* transform: rotate(-180deg); */
  color: #ffc107;
  text-decoration: none;
}
.loc {
  display: flex;
  margin-bottom: 5px;
}
.loc p{
  margin: 0px;
}
.copyright{
  font-size: 14px;
  color: #C9C9C9;
  margin-top: 40px;
  
}
@keyframes transitionIn {
  from {
    opacity: 0;
    transform: margin();
  }
  to {
    opacity: 1;
    transform: rotateX(0deg);
  }
}
@media screen and (max-width: 550px) {
  .footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .links {
    margin-left: 0px;
  }
  /* .contact {
    margin-left: 50px;
  } */
}
