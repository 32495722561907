.about {
  display: flex;
}
.text {
  /* float: left;*/
  width: 50%;
  padding-left: 50px;
  padding-right: 50px;
}
.about img {
  margin-top: 10px;
}

.about h3 {
  margin-top: 10px;
  color: #666666;
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 0px;
  line-height: 26px;

  padding: 0;
  /* text-align: center;
  padding: 5px 120px; */
}
.img img {
  width: 500px;
  height: 400px;
}
@media screen and (max-width: 860px) {
  .img img {
    width: 400px;
    height: 430px;
  }
  .text {
    padding-left: 20px;
    padding-right: 20px;
  }
}
@media screen and (max-width: 720px) {
  .img img {
    width: 350px;
    height: 430px;
  }
  .text {
    /* float: left;*/
    width: 50%;
    padding-left: 10px;
    padding-right: 10px;
  }
}
@media screen and (max-width: 650px) {
  .about {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .img img {
    width: 350px;
    height: 350px;
  }
  .main {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .text {
    /* float: left;*/
    width: 100%;
    padding-left: 40px;
    padding-right: 40px;
  }
}

@media screen and (max-width: 400px) {
  .img img {
    width: 350px;
    height: 350px;
  }
  .main {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .text {
    /* float: left;*/
    width: 100%;
    padding-left: 10px;
    padding-right: 10px;
  }
}
@media screen and (max-width: 380px) {
  .img img {
    width: 300px;
    height: 300px;
  }
  .text {
    /* float: left;*/

    padding-left: 5px;
    padding-right: 5px;
  }
}
